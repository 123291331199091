import { Box, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme } from '@mui/material'
// import stylesLogo from '../Accounts/Account.styles'
import ErrorIcon from '@mui/icons-material/Error';
function NotFoundHeading() {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <ListItem disablePadding>
        <ListItemIcon>
          <ErrorIcon color='error' fontSize='medium' sx={{ mr: "0.25rem" }} />
          {/* <Avatar 
            alt={""}
            sx={{...stylesLogo.avatarStyle, background:theme.palette.primary.light}}
            src={<ReportGmailerrorredIcon />} /> */}
        </ListItemIcon>
        <ListItemText
          sx={{ "& .MuiListItemText-primary": { color: theme.palette.info.main } }}
          primary={
            <Box
              flexDirection={mobileScreen ? "column" : "row"}
              alignItems={mobileScreen ? "flex-start" : "center"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem"
              }}>
              <span style={{ fontSize: mobileScreen && "1rem" }}>No Account Found</span>
            </Box>
          }
        />
      </ListItem>
    </>
  )
}

export default NotFoundHeading;