import { Link, Switch, useTheme } from '@mui/material';
import React from 'react'
import linkedAccountsSectionStyles from '../Accounts/LinkedAccountsSection.styles';

function SelectAll(props) {
  const theme = useTheme();
  return (
      <Link
        display={props.consentsNum>1?"block":"none"}
        color={theme.palette.primary.main}
        onClick={() => {
          props.toggleSelectAll();
        }}
        sx={linkedAccountsSectionStyles.changeNumberLink}
      >
        Select All
        <Switch
          checked={props.selectAll}
          onChange={props.toggleSelectAll}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Link>  )
}

export default SelectAll