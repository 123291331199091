import sizeFontCustom from "../Discovery/fontSize.styles"

const addAccountStyles = {
  container: {
    flex: "2",
    display: "flex",
    justifyContent: { xs: "start", md: "start" },
    flexDirection: "column",
    minHeight: '100vh',
    alignItems: "start",
    paddingTop: {xs:"5.25rem", md:"none"},
  },
  flexRowSb: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  flexRowC: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  title: {
    ...sizeFontCustom.titleMain,
    fontWeight: "900",
    marginBottom: {
      sm: "0.75rem",
      xs: "0.75rem",
      md: "0",
    },
  },
  subTitle: {
    ...sizeFontCustom.heading,
    textAlign: "left"
  },
  topFipContainer: {
    justifyContent: "start",
    gap: {
      xs: "0.75rem",
      md: "1rem",
    },
  },
  changeNumberLink: { cursor: "pointer", textDecoration: "none", fontWeight: "700", textTransform: "capitalize", ...sizeFontCustom.cardTitle },

}
export default addAccountStyles;