import './App.css';
import { Component } from 'react';

import Home from './components/Home';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
// import Consent from './components/Consent';
import { Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import finvu from './assets/finvu.png';
import { postMessage } from './utils/common.utils';
import Error from './components/Error';
import ConsentStatus from './components/ConsentStatus';
import Header from './components/common/Header';
import AccountDiscovery from './components/AccountDiscovery';
import AddNumber from './components/AddNumber';
import Toast from './components/Toast/ToastContainer';
import PaymentConsent from './components/PaymentConsent';
import { ConsentContext } from './components/Context/ConsentContext';
import { registerEvent } from './utils/tracking.utils';

const defaultConfig = {
	fontFamily: 'Lato',
	primaryColor: '#017aff',
	secondaryColor: '#F5F5F5',
	headingColor: '#191c1f',
	subHeadingColor: '#0e65d7',
	textColor: '#757779',
	btnHeight: '48px',
	btnWidth: '327px',
	borderRadius: '40px',
};
class App extends Component {
	constructor(props) {
		super(props);
		window.finvuClient.open();
		registerEvent({
			'LINKED_ACCOUNTS_DISCOVERED': {
				category: 'discovery',
				fipId: '',
				count: 0,
			},
			'UNLINKED_ACCOUNTS_DISCOVERED': {
				category: 'discovery',
				fipId: '',
				count: 0,
			},
		});
		const queryParams = new URLSearchParams(window.location.search);
		this.ecreq = queryParams.get('ecreq');
		this.reqdate = queryParams.get('reqdate');
		this.fi = queryParams.get('fi') ? queryParams.get('fi') : '';
		this.requestorType = queryParams.get('requestorType')
			? queryParams.get('requestorType')
			: null;
		global.requestorType = queryParams.get('requestorType')
			? queryParams.get('requestorType')
			: null;
		global.isAddAccount = queryParams.get('isAddAccount') ? true : false;
		global.mobileNumberArray = [];
		this.state = {
			isLoader: false,
			initialLoader: false,
			configDetails: defaultConfig,
		};
	}
	componentDidMount() {
		if (!this.ecreq || !this.fi || !this.reqdate) {
			postMessage('session', 'FAILURE');
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (this.props.history.action === 'POP') {
				window.confirm(
					'Are you sure to go back ? Consent will not be actioned.'
				);
			}
		}
	}
	componentWillUnmount() {
		if (this.props.history.action === 'POP') {
			window.confirm('Are u sure u want to mountmount');
		}
	}
	updateLoader = (val) => {
		this.setState({ isLoader: val });
	};
	updateInitialLoader = (val) => {
		this.setState({ initialLoader: val });
	};
	updateEntityID = (data) => {
		this.setState({ configDetails: data });
	};

	render() {
		return (
			<ConsentContext>
				<div
					className="App"
					style={{ fontFamily: this.state.configDetails?.fontFamily }}
				>
					<Header customDetails={this.state.configDetails} />
					{this.ecreq && this.fi && this.reqdate ? (
						<Switch>
							<Route
								exact
								path="/"
								render={(props) => (
									<>
										<Login
											updateEntityID={this.updateEntityID}
											updateInitialLoader={this.updateInitialLoader}
											updateLoader={this.updateLoader}
											{...props}
											customDetails={this.state.configDetails}
										/>
									</>
								)}
							/>
							<Route
								exact
								path="/home"
								render={(props) => (
									<>
										<Home
											updateLoader={this.updateLoader}
											{...props}
											customDetails={this.state.configDetails}
										/>
									</>
								)}
							/>
							<Route
								exact
								path="/login/:fid?"
								render={(props) => (
									<>
										<Login
											updateEntityID={this.updateEntityID}
											updateInitialLoader={this.updateInitialLoader}
											updateLoader={this.updateLoader}
											{...props}
											customDetails={this.state.configDetails}
										/>
									</>
								)}
							/>
							<Route
								exact
								path="/accounts/:number/:fid?"
								render={(props) => (
									<>
										<AccountDiscovery
											updateLoader={this.updateLoader}
											{...props}
											customDetails={this.state.configDetails}
										/>
									</>
								)}
							/>
							<Route
								exact
								path="/payment-consent"
								render={(props) => (
									<>
										<PaymentConsent
											updateLoader={this.updateLoader}
											{...props}
											customDetails={this.state.configDetails}
										/>
									</>
								)}
							/>
							<Route
								exact
								path="/NoAccountFound/:number"
								render={(props) => (
									<>
										<AddNumber
											updateLoader={this.updateLoader}
											{...props}
											customDetails={this.state.configDetails}
										/>
									</>
								)}
							/>
							<Route
								exact
								path="/error"
								render={(props) => (
									<>
										<Error
											{...props}
											customDetails={this.state.configDetails}
										/>
									</>
								)}
							/>
							<Route
								exact
								path="/consent-status"
								render={(props) => (
									<>
										<ConsentStatus
											{...props}
											customDetails={this.state.configDetails}
										/>
									</>
								)}
							/>
						</Switch>
					) : (
						<div className="centervs px-2">
							<h1 style={{ fontFamily: this.state.configDetails?.fontFamily }}>
								Oops!
							</h1>
							<h2 style={{ fontFamily: this.state.configDetails?.fontFamily }}>
								404 Not Found
							</h2>
							<div
								className="error-details"
								style={{ fontFamily: this.state.configDetails?.fontFamily }}
							>
								Sorry, an error has occured, Requested page not found!
							</div>
						</div>
					)}

					{/* To display Success, Warning or Error messages to user */}
					<div className="toast-message">
						<Toast />
					</div>
					{/* To display API call Loader */}
					{this.state.isLoader ? (
						<div>
							<div className="application-loading-container">
								<div className="snippet" data-title=".dot-pulse">
									<div className="stage">
										<div className="dot-pulse-btn"></div>
									</div>
								</div>
								{/* <img className="image" src={LoaderImage} alt="" width="120" height="120"></img> */}
							</div>
						</div>
					) : null}
					{/* To display Initial Loader */}
					{this.state.initialLoader ? (
						<div>
							<div className="application-loading-initial">
								<img
									alt="Finvu Logo"
									src={finvu}
									style={{ width: '109px', height: '60px', marginTop: '15px' }}
								/>
								<p
									className="label-size"
									style={{ marginTop: '20px', fontSize: '19px' }}
								>
									We are checking your registration status with Finvu Account
									Aggregator (RBI licensed). Please wait...
								</p>
								<div
									style={{
										color: '#f90e1a',
										textAlign: 'left',
										fontSize: '21px',
									}}
								>
									<b>DO NOT hit REFRESH or BACK</b>
								</div>
								<br />
								<div className="snippet" data-title=".dot-pulse">
									<div className="stage">
										<div className="dot-pulse"></div>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</ConsentContext>
		);
	}
}

export default App;
