export const trackEvent = (eventName, params = {}) => {
	try {
		window.finvuClient.track(eventName, params);
	} catch (e) {
		console.log(e);
	}
};

export const registerEvent = (customEvents) => {
	try {
		window.finvuClient.registerCustomEvents(customEvents);
	} catch (e) {
		console.log(e);
	}
};
