import sizeFontCustom from "../Discovery/fontSize.styles"

const stylesLogo = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "normal",
    fontSize: "0.9rem",
    width: "fit-content",
  },
  avatarStyle: {
    padding: "0.35rem",
    mr: "0.25rem",
    width: 35,
    height: 35,
    objectFit: "contain",
    "& .MuiAvatar-img": {
      objectFit: "contain"
    }
  },
  requestDate: {
    display: { xs: "block", md: "none" },
    fontSize: "0.75rem",
    color: "#81858F",
    minWidth: "100%",
  },
  account: {
    display: "inline",
    ...sizeFontCustom.subHeading,
    color: "#81858F",
    minWidth: "100%",
  },
  paper: {
    width: "100%",
    padding: { xs: "1rem", md: "1.2rem" },
  },
};
export default stylesLogo;
