import { Grid } from "@mui/material"
import SearchBar from "../SearchBar/SearchBar"
import OtherFIUCard from "./OtherFIUCard";

function SearchFip(props) {
  return (
    <>
      <SearchBar onSearch={props.handleSearch} />
      <Grid container direction="row" spacing="1.875rem">
        {props.searchResults.map((bankObject) => (
          props.customDetails?.excludeFIP ?
            !props.customDetails?.excludeFIP.includes(bankObject.fipId) &&
            <OtherFIUCard
              xs={12}
              sm={12}
              md={6}
              lg={4}
              bankObject={bankObject}
              handleOtherFipCheckboxChange={props.handleOtherFipCheckboxChange}
              key={bankObject.fipId}
              checkedBanksList={props.topFip}
              fontSize="0.875rem"
              boxShadow="none"
              height="3.75rem"
            /> :
            <OtherFIUCard
              xs={12}
              sm={12}
              md={6}
              lg={4}
              bankObject={bankObject}
              handleOtherFipCheckboxChange={props.handleOtherFipCheckboxChange}
              key={bankObject.fipId}
              checkedBanksList={props.topFip}
              fontSize="0.875rem"
              boxShadow="none"
              height="3.75rem"
            />
        ))}
      </Grid>
    </>
  )
}

export default SearchFip
