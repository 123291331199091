import {
	Box,
	Button,
	Grid,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
	Dialog,
	DialogContent,
	DialogActions,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FipDiscoveredAccountsSection from './FipDiscoveredAccountsSection';
import WhyShare from '../Sections/WhyShare';
import linkedAccountsSectionStyles from '../Accounts/LinkedAccountsSection.styles';
import discoveryStyles from './AccountDiscoveryContainer.styles';
import { useState, useEffect } from 'react';
import FipNotDiscoveredAccountsSection from './FipNotDiscoveredAccountsSection';
import { isAccountLinked } from '../../utils/helper.utils';

function AccountDiscoveryContainer(props) {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [changingMobileNumber, setChangingMobileNumber] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [accountsNotLinked, setAccountsNotLinked] = useState([]);
	const [accountsNotLinkedLength, setAccountsNotLinkedLength] = useState(0);
	const [selectedAccountLinkedLength, setSelectedAccountLinkedLength] =
		useState(0);

	// Logic to calculate accounts that are selected but not linked
	useEffect(() => {
		const accountsNotLinkedSet = new Set();
		const unlinkedAccounts = props.accountState.selectedAccounts.filter(
			(account) => {
				// Create a unique key for each account
				const uniqueKey = `${account.maskedAccNumber}_${account.FIType}_${account.accType}`;
				if (!accountsNotLinkedSet.has(uniqueKey)) {
					accountsNotLinkedSet.add(uniqueKey);
					return !isAccountLinked(
						account,
						props.accountState.linkedAccountsFull
					);
				}
				return false;
			}
		);

		// Set the unlinked accounts to the state
		setAccountsNotLinked(unlinkedAccounts);
		setAccountsNotLinkedLength(unlinkedAccounts.length); // Updates length here
	}, [
		props.accountState.selectedAccounts,
		props.accountState.linkedAccountsFull,
	]);

	const handleContinueClick = () => {
		if (
			accountsNotLinkedLength > 0 &&
			selectedAccountLinkedLength !==
				props.accountState.discoveredAccounts.length
		) {
			setOpenDialog(true);
		} else {
			props.history.push(`/payment-consent`);
		}
	};

	const handleDialogClose = (proceed) => {
		setOpenDialog(false);
		if (proceed) {
			props.history.push(`/payment-consent`);
		}
	};
	return (
		<>
			<Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
				<DialogContent>
					Before proceeding, please ensure that you have linked your discovered
					accounts.
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleDialogClose(false)}
						sx={{ color: 'red' }}
					>
						Cancel
					</Button>
					<Button
						onClick={() => handleDialogClose(true)}
						sx={{ color: '#0f52ba' }}
					>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>
			<Box
				sx={{
					...discoveryStyles.container,
					padding: mobileScreen
						? '2.25rem 1.5rem 5.5rem 1.5rem'
						: '6rem 1.875rem',
					minWidth: mobileScreen ? '100vw' : 'auto',
					backgroundColor: theme.palette.primary.light,
					gap: mobileScreen ? '1.25rem' : '1.5rem',
				}}
			>
				<Box width={'100%'}>
					<Box sx={discoveryStyles.headingContainer}>
						<Box sx={discoveryStyles.heading}>
							{!mobileScreen && global.isAddAccount ? (
								<IconButton
									disableRipple
									sx={{ pl: 0 }}
									onClick={props.handleBack}
								>
									<ArrowBackIcon color="primary" />
								</IconButton>
							) : null}
							<Typography sx={discoveryStyles.headingText}>
								Verify Bank Accounts
							</Typography>
						</Box>
						{!mobileScreen ? (
							<Button
								size="large"
								variant="contained"
								onClick={handleContinueClick}
								sx={discoveryStyles.nextButton}
							>
								Continue
							</Button>
						) : null}
					</Box>
					<Typography
						color={theme.palette.black60}
						sx={discoveryStyles.subTitleText}
					>
						Link your accounts with an OTP sent to your registered mobile number
						<span
							style={{
								color: theme.palette.info.main,
								fontWeight: '700',
							}}
						>
							{' '}
							{props?.accountState?.noDiscoveredAccounts?.phoneNumber
								? props?.accountState?.noDiscoveredAccounts?.phoneNumber
								: global.mobileNumber
								? global.mobileNumber
								: global.mobileNumberArray[0]}{' '}
						</span>
						{/* {global.sdkOptions.allowDifferentMobile==="Y"&&
            <Link
              color={theme.palette.primary.main}
              onClick={()=>{ props.history.push(`/NoAccountFound/${global.mobileNumber}?step=${101}&fip=${props.match.params.fid}`) }}
              sx={discoveryStyles.changeNumberLink}
            >
              CHANGE MOBILE  .<br />
            </Link>}*/}
						by the institution.
					</Typography>
				</Box>
				{!mobileScreen && (
					<Box
						sx={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							right: 0,
							backgroundColor: 'white',
							padding: '2rem',
							zIndex: 6,
						}}
					>
						<WhyShare {...props} />
					</Box>
				)}
				<Grid container sx={discoveryStyles.discoveredAccountsContainer}>
					{props.accountState.discoveredAccounts.map((accountsSingleFip) => {
						return (
							<FipDiscoveredAccountsSection
								key={accountsSingleFip.fipId}
								changingMobileNumber={changingMobileNumber}
								setChangingMobileNumber={setChangingMobileNumber}
								accountsSingleFip={accountsSingleFip}
								accountsNotLinkedLength={accountsNotLinkedLength}
								setAccountsNotLinkedLength={setAccountsNotLinkedLength}
								selectedAccountLinkedLength={selectedAccountLinkedLength}
								setSelectedAccountLinkedLength={setSelectedAccountLinkedLength}
								{...props}
							/>
						);
					})}
					{props.accountState.noDiscoveredAccounts.fips.length > 0 && (
						<FipNotDiscoveredAccountsSection
							changingMobileNumber={changingMobileNumber}
							setChangingMobileNumber={setChangingMobileNumber}
							{...props}
						/>
					)}
				</Grid>
			</Box>

			{mobileScreen ? (
				<Box
					sx={{
						...linkedAccountsSectionStyles.buttonGroup,
						bottom: mobileScreen ? '1px' : '1.875rem',
						backgroundColor: mobileScreen ? 'white' : 'none',
						gap: mobileScreen ? '1rem' : '1.25rem',
						width: mobileScreen ? '100vw' : '20%',
					}}
				>
					{global.isAddAccount ? (
						<Box
							sx={{
								bottom: mobileScreen ? '0rem' : '1.875rem',
								right: mobileScreen ? '0rem' : '0rem',
								backgroundColor: mobileScreen ? 'white' : 'none',
								gap: mobileScreen ? '1rem' : '1.25rem',
								width: mobileScreen ? '100vw' : '20%',
								flexWrap: 'wrap',
								...linkedAccountsSectionStyles.buttonGroup,
							}}
						>
							<Button
								variant="outlined"
								fullWidth={mobileScreen}
								color="primary"
								onClick={() => props.handleBack()}
								sx={{ flex: 1, background: theme.palette.primary.white }}
							>
								Back
							</Button>
							<Button
								variant="contained"
								fullWidth={mobileScreen}
								onClick={handleContinueClick}
								sx={{
									backgroundColor: props.customDetails.primaryColor,
									flex: 1,
								}}
							>
								Continue
							</Button>
							<WhyShare {...props} />
						</Box>
					) : null}
					<Button
						variant="contained"
						fullWidth={mobileScreen}
						onClick={handleContinueClick}
						sx={{ backgroundColor: props.customDetails.primaryColor, flex: 1 }}
					>
						Continue
					</Button>
				</Box>
			) : null}
		</>
	);
}

export default AccountDiscoveryContainer;
