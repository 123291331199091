import { Button, Fab, Grid } from "@mui/material";
import sizeFontCustom from "../Discovery/fontSize.styles"

function AddNewCard(props) {
  const isActiveFlag = props?.selectedConsentsState?.includes(props?.consentHandle)
		? true
		: false;
    const redirect = () => {
    global.fi = global.FIGroups[props.fiType]
    props.history.push(`/home/?number=${global.mobileNumber}&isAddAccount=${true}`, { fiTypes: global.FIGroups[props.fiType] },)
  }
  const handleAddAccount = () => {
    if(!isActiveFlag){
      return
    }
    redirect()
    return
  }
  return (
    <Grid
      container
      padding={"1rem"}
      onClick={handleAddAccount}
      sx={{
        alignItems: "center",
        borderRadius: "20px",
        border: `1.5px dashed ${!isActiveFlag?"lightgray":props.customDetails?.primaryColor}`,
        textAlign: "left",
      }}
    >
      <div>
        <Fab
          disabled={!isActiveFlag}
          component={"button"}
          disableRipple
          onClick={handleAddAccount}
          color="primary"
          aria-label="add"
          sx={{ minHeight: 0, width: "25px", height: "25px" }}
        >
          +
        </Fab>
        <Button
          disabled={!isActiveFlag}
          variant="text" 
          sx={sizeFontCustom.subHeading}>
          {props.fiType}
        </Button>
      </div>
    </Grid>
  );
}

export default AddNewCard;
