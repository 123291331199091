import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { transformFiType } from '../../../hepler/functions';
import sizeFontCustom from '../../Discovery/fontSize.styles';
import { useTheme } from '@emotion/react';
import { useState } from 'react';

function ChipSet({ fiTypes }) {
	const theme = useTheme();
	const maxFiSize = 3;
	const toolTipFitype = () =>
		fiTypes.slice(maxFiSize).map((fi) => {
			return (
				<Typography sx={sizeFontCustom.toolTipText} key={fi}>
					{transformFiType(fi)}
				</Typography>
			);
		});

	const [openToolTip, setOpenToolTip] = useState(false);

	const handleClick = () => {
		setOpenToolTip((prevState) => !prevState);
	};
	return (
		<div style={{ display: 'flex' }}>
			{fiTypes.slice(0, maxFiSize).map((info) => (
				<Chip
					key={info}
					variant="outlined"
					label={transformFiType(info)}
					size="small"
					sx={{
						mr: 0.5,
						mt: 0.5,
						fontSize: '0.675rem',
						width: 'fit-content',
						color: '#81858F',
					}}
				/>
			))}
			{fiTypes.length - maxFiSize > 0 && (
				<Tooltip
					title={toolTipFitype()}
					open={openToolTip}
					onClick={handleClick}
				>
					<Box
						sx={{
							display: 'flex',
							color: theme.palette.primary.main,
							mt: '0.1rem',
							alignItems: 'center',
						}}
					>{`+ ${fiTypes.length - maxFiSize} more`}</Box>
				</Tooltip>
			)}
		</div>
	);
}

export default ChipSet;
