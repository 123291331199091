import { Box, Button, FormControl, FormControlLabel, IconButton, Link, List, ListItem, ListItemText, Radio, RadioGroup, TextField, Typography, useTheme } from '@mui/material';
import sizeFontCustom from "../Discovery/fontSize.styles"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './NoAccountContainer.styles';
import { useState } from 'react';
import { isPhoneNumberValid } from '../../hepler/functions';

function EmbededChangeNumber(props) {
  const { setSuperState, superState,  handleOTPChange, sendOtp, verifyOtp, handleToggleNumber } = props
  const theme = useTheme();
  const [phoneNumberAlternate, setPhoneNumberAlternate] = useState('');
  const [changeNumber, setChangeNumber] = useState("other")
  const [isPhoneInValid, setIsPhoneInValid] = useState(false)
  const handleChange = (event) => {
    setChangeNumber(event.target.value);
  };
  const handleBackArrow = () => {
    props?.setChangingMobileNumber(false)
    props?.setShowChangeNumber(false)
  }
  const handleSubmit = async () => {
    if (superState.noAccount === 101) {
      if (changeNumber === "other") {
        if(isPhoneNumberValid(phoneNumberAlternate)){
          await setSuperState(phoneNumberAlternate, null)
          sendOtp()
        }else{
          setIsPhoneInValid(false)
        }
      } else {
        handleToggleNumber(changeNumber)
      }

    } else if (superState.noAccount === 102) {
      verifyOtp()
    } else {
      await setSuperState(null, 101)
    }
  }
  return (
    <List disablePadding>
      <ListItem disablePadding>
        <ListItemText
          sx={{
            "& .MuiListItemText-primary": { color: theme.palette.info.main, fontWeight: 900, mb: "0.75rem" },
            "& .MuiListItemText-secondary": { color: theme.palette.black60, ...sizeFontCustom.subHeading },
            marginBottom: "1.5rem"
          }}
          primary={
            <Box
              sx={styles.heading}>
              {global.isAddAccount &&
                <IconButton onClick={handleBackArrow} sx={{ p: 0 }}>
                  <ArrowBackIcon sx={{ color: props.customDetails.primaryColor, fontSize: "1.25rem" }} />
                  {/* <CloseIcon color='primary'  sx={{fontSize:"0.75rem", mr:"1rem"}}/> */}
                </IconButton>
              }
              <Typography
                sx={styles.changeNumberText}
              >
                Change Mobile Number
              </Typography>

            </Box>

          }
          secondary={
            <>
              {
                superState.noAccount === 102 ?
                  `OTP sent to ${phoneNumberAlternate}`
                  : (superState.noAccount === 101 ?
                    <>
                      Select an already verified number for finding your
                      accounts or use another number
                      <FormControl sx={{ display: "block" }}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={changeNumber}
                          onChange={handleChange}

                        >
                          {global.mobileNumberArray.map((el, i) => 
                            <FormControlLabel 
                              key={el} 
                              value={el} 
                              control={<Radio size='small' />} 
                              label={<Typography sx={sizeFontCustom.subHeading}>{el}</Typography>} 
                            />
                          )}
                          <FormControlLabel 
                            sx={sizeFontCustom.subHeading} 
                            value="other" 
                            control={<Radio size='small' />} 
                            label={<Typography sx={sizeFontCustom.subHeading}>{"Add New Number"}</Typography>} 
                          />
                        </RadioGroup>
                      </FormControl>
                    </>
                    : <>
                      We have discovered no bank accounts associated with mobile number {" "}
                      <strong>{props.match.params?.number}</strong></>
                  )
              }

            </>
          }
        />
      </ListItem>
      <>
        {
          superState.noAccount === 101 && changeNumber === "other" ?
            <ListItem disablePadding>
              <TextField
                name='Phone Number'
                variant='outlined'
                type='number'
                size='small'
                label={<Typography sx={sizeFontCustom.heading}>Enter Phone Number</Typography>}
                inputProps={{ style: { fontSize: "0.875rem", type:"number" } }}
                value={phoneNumberAlternate}
                onChange={(e) => setPhoneNumberAlternate(e.target.value)}
                fullWidth
                error={isPhoneInValid}
                helperText={isPhoneInValid&&"Enter a valid phone number"}
                sx={{ mb: "1rem" }}
              />
            </ListItem>
            :
            (superState.noAccount === 102 ?
              <>
                <TextField
                  name='OTP'
                  fullWidth
                  size='small'
                  label="Enter OTP"
                  value={superState.OTP}
                  onChange={handleOTPChange}
                  sx={{ mb: "1.25rem" }}

                />
                {!superState.isResend
                  ?
                  <Typography
                    sx={styles.resendText}>
                    Resent OTP in
                    <strong
                      style={{
                        color: theme.palette.error.main,
                      }}>
                      {" " + superState.time} sec
                    </strong>
                  </Typography>
                  :
                  <Link
                    display={"flex"}
                    underline='none'
                    disabled={!superState.isResend}
                    onClick={sendOtp}
                    sx={{
                      ...sizeFontCustom.subHeading,
                      cursor: "pointer",
                      m: "0 0 0.875rem 0",
                      color: `${(superState.isResend) ? theme.palette.primary.main : 'grey'}`,
                    }}
                  >
                    Resend OTP
                  </Link>
                }
              </>
              : null
            )
        }
      </>
      <ListItem disablePadding>
        <Button
          variant='contained'
          fullWidth
          disabled={superState.noAccount === 101 ? changeNumber === "other" ? phoneNumberAlternate.length !== 10 : false : superState.noAccount === 102 ? superState.OTP.length < 6 : false}
          onClick={handleSubmit}
          sx={{
            textTransform: "capitalize",
            ...sizeFontCustom.subHeading
          }}
        >
          Continue
        </Button>
      </ListItem>
    </List>

  )
}

export default EmbededChangeNumber