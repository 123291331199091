import sizeFontCustom from '../Discovery/fontSize.styles';

const linkedAccountsFIStyles = {
	headingContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: { xs: 'space-between', md: 'flex-start' },
	},
	name: {
		...sizeFontCustom.heading,
		mr: '1.23rem',
		display: 'flex',
		alignItems: 'center',
	},
	count: {
		margin: '0.5rem',
		width: { xs: 23, md: 23 },
		height: { xs: 23, md: 23 },
		...sizeFontCustom.linkAccountsText,
		color: 'grey',
	},
	accordian: {
		width: '100%',
		'::before': {
			backgroundColor: 'white!important',
		},
	},
	accordianSummary: {
		p: 0,
		flexDirection: 'column',
		minWidth: '100%',
		alignItems: 'start',
		width: '100%',
		mt: '0',
		'& .MuiAccordionSummary-content': {
			width: '100%',
			mt: 0,
		},
		'& .MuiAccordionSummary-content.Mui-expanded': {
			margin: 0,
		},
	},
};
export default linkedAccountsFIStyles;
