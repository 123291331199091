const loginLeftStyles = {
  container: {
    display: "flex",
    justifyContent: { xs: "start", md: "center" },
    flexDirection: { xs: "column", md: "row" },
    minHeight: '100vh',
    alignItems: "start"
  },
  help: {
    cursor: "pointer",
    paddingRight: 0,
    textTransform: "capitalize",
    display: { xs: "block", md: "none" }
  }
}

export default loginLeftStyles;