import React, { Component } from 'react';
import AuthApis from '../hepler/authApis.service';
import { toast } from 'react-toastify';
import { postMessage } from '../utils/common.utils';
import SetTheme from './common/SetTheme';
import NoAccountContainer from './NoAccount/NoAccountContainer';
import { Box } from '@mui/material';
import EmbededChangeNumber from './NoAccount/EmbededChangeNumber';
import WhyShare from './Sections/WhyShare';
import linkedAccountsSectionStyles from './Accounts/LinkedAccountsSection.styles';

export default class AddNumber extends Component {
	constructor() {
		super();
		const queryParams = new URLSearchParams(window.location.search);
		this.state = {
			time: 60,
			do: false,
			complete: false,
			isResend: true,
			noAccount: queryParams.get('step')
				? parseInt(queryParams.get('step'))
				: 101,
			newMobileNumber: '',
			OTP: '',
			selectedNumber: this.props?.match?.params?.number
				? this.props.match.params?.number
				: global.mobileNumberArray[0],
		};
		this.fip = queryParams.get('fip') || this.props?.fipSelect;
		this.authApis = new AuthApis();
	}
	initiate = () => {
		// if (this.state.time !== 0) {
		this.setState((prevState, prevProps) => ({
			time: prevState.time - 1,
		}));
		if (this.state.time === 0) {
			clearInterval(this.id);
			this.setState({ complete: true, time: 60, isResend: true });
		}
		// }
	};
	sendOtp = () => {
		if (this.state.selectedNumber !== 'other') {
			if (this.props?.isComponent) {
				this.props.setShowChangeNumber(false);
				// this.props.history.push(
				//   `/accounts/${this.state.selectedNumber}/${this.fip}`
				// );
			} else {
				this.props.history.push(
					`/home/?number=${this.state.selectedNumber}&isAddAccount=${true}`
				);
			}
		} else {
			this.getOtp();
			this.authApis.mobileVerifyReq(this.state.newMobileNumber).then((res) => {
				if (res?.status === 'SEND') {
					this.setState({ noAccount: 102 });
				} else {
					toast.error(res?.message);
					console.log(res);
					if (res?.status === 'FAILURE') {
						setTimeout(() => {
							this.props.history.push({
								pathname: `/consent-status`,
								state: {
									status: 'Consent denied',
									statusCode: 403,
								},
							});
						}, 3000);
						postMessage('session', 'FAILURE');
					} else if (res?.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
				}
			});
		}
	};
	useExistingNumber = (number) => {
		global.mobileNumber = number;
		this.props.history.push(`/home/?number=${number}&isAddAccount=${true}`);
	};
	verifyOtp = () => {
		this.props.updateLoader(true);
		this.authApis
			.varifyMobile(this.state.newMobileNumber, this.state.OTP)
			.then((res) => {
				console.log(res);
				this.props.updateLoader(false);
				if (res?.status === 'ACCEPT') {
					global.mobileNumberArray.push(this.state.newMobileNumber);
					if (this.props?.isComponent) {
						this.props?.setShowChangeNumber(false);
						this.props?.setChangingMobileNumber(false);
						this.props?.discoverAccount(
							[],
							this.props?.fipSelect,
							this.state.newMobileNumber
						);
						// this.props.history.push(
						//   `/accounts/${this.state.newMobileNumber}/${this.fip}`
						// );
					} else {
						global.mobileNumber = this.state.newMobileNumber;
						this.props.history.push(
							`/home/?number=${this.state.newMobileNumber}&isAddAccount=${true}`
						);
					}
				} else {
					console.log(res);
					clearInterval(this.id);
					this.setState({ complete: true, time: 60, isResend: true, OTP: '' });
					if (res?.status === 'FAILURE') {
						toast.error(res?.message);

						postMessage('session', 'FAILURE');
					} else if (res?.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
				}
			});
	};
	getOtp = () => {
		this.setState({ isResend: false, OTP: '' });
		this.id = setInterval(this.initiate, 1000);
	};
	selectNumber = (number) => {
		global.mobileNumber = number;
		this.setState({ selectedNumber: number });
	};
	handleBack = () => {
		global.mobileNumber = this.props.match.params?.number;
		if (this.props?.isComponent) {
			this.props.setShowChangeNumber(false);
		} else {
			this.props.history.push(
				`/home/?number=${this.props.match.params?.number}&isAddAccount=${true}`
			);
		}
	};

	handleOTPChange = (e) => {
		const regex = /^$|^[A-Z0-9]+$/;
		const inputValue = e.target.value.toUpperCase();
		if (regex.test(inputValue)) {
			this.setState({ OTP: inputValue });
		}
	};
	setTheme = (theme) => {};

	updateState = async (newMobileNumber, noAccount) => {
		if (newMobileNumber) {
			this.setState({
				selectedNumber: 'other',
				newMobileNumber: newMobileNumber,
			});
		} else {
			this.setState({
				noAccount: noAccount,
			});
		}
	};
	handleToggleNumber = (number) => {
		this.setState({ newMobileNumber: number });
		if (this.props?.isComponent) {
			this.props?.setAccountState((prevState) => ({
				...prevState,
				isLoding: true,
			}));
			this.props?.setShowChangeNumber(false);
			this.props?.setChangingMobileNumber(false);
			this.props?.discoverAccount([], this.props?.fipSelect, number);
		} else {
			global.mobileNumber = number;
			this.props.history.push(
				`/home/?number=${this.state.newMobileNumber}&isAddAccount=${true}`
			);
		}
	};

	render() {
		return !this.props.isComponent ? (
			<Box
				sx={{
					display: 'flex',
					justifyContent: { xs: 'start', md: 'center' },
					flexDirection: { xs: 'column', sm: 'column', md: 'row' },
					minHeight: '100vh',
					alignItems: 'start',
				}}
			>
				<SetTheme
					customDetails={this.props.customDetails}
					setTheme={this.setTheme}
				/>
				<NoAccountContainer
					handleBack={this.handleBack}
					setSuperState={this.updateState}
					superState={this.state}
					handleOTPChange={this.handleOTPChange}
					sendOtp={this.sendOtp}
					verifyOtp={this.verifyOtp}
					handleToggleNumber={this.handleToggleNumber}
					{...this.props}
				/>
				<Box
					sx={{
						bottom: { xs: '0rem', xl: '1.875rem' },
						right: '0rem',
						backgroundColor: { xs: 'white', xl: 'transparent' },
						gap: { xs: '1rem', xl: '1.25rem' },
						width: '100vw',
						flexWrap: 'wrap',
						...linkedAccountsSectionStyles.buttonGroup,
					}}
				>
					<WhyShare {...this.props} />
				</Box>
			</Box>
		) : (
			<>
				<EmbededChangeNumber
					handleBack={this.handleBack}
					setSuperState={this.updateState}
					superState={this.state}
					handleOTPChange={this.handleOTPChange}
					sendOtp={this.sendOtp}
					verifyOtp={this.verifyOtp}
					handleToggleNumber={this.handleToggleNumber}
					{...this.props}
				/>
				<WhyShare {...this.props} />
			</>
		);
	}
}
