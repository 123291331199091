import React, { useState } from 'react';
import sizeFontCustom from '../../Discovery/fontSize.styles';
import {
	ListItemIcon,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Field = ({
	property,
	value,
	customDetails,
	tooltipText,
	children,
	styles,
	valueStyles,
	popoverComponent,
}) => {
	//Themeing
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [openToolTip, setOpenToolTip] = useState(false);

	const handleClick = () => {
		setOpenToolTip((prevState) => !prevState);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', ...styles }}>
			{tooltipText ? (
				<div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
					<Typography
						sx={{
							...sizeFontCustom.consentField,
							maxWidth: mobileScreen ? '60vw' : '19vw',
							fontFamily: customDetails?.fontFamily,
							textAlign: 'left',
							fontWeight: 700,
						}}
					>
						{property}
					</Typography>
					{popoverComponent ? (
						popoverComponent
					) : (
						<Tooltip
							open={openToolTip}
							title={tooltipText}
							onClick={handleClick}
						>
							<ListItemIcon>
								<InfoOutlinedIcon color="primary" sx={{ fontSize: 16 }} />
							</ListItemIcon>
						</Tooltip>
					)}
				</div>
			) : (
				<Typography
					sx={{
						...sizeFontCustom.consentField,
						maxWidth: mobileScreen ? '60vw' : '19vw',
						fontFamily: customDetails?.fontFamily,
						textAlign: 'left',
						fontWeight: 700,
					}}
				>
					{property}
				</Typography>
			)}
			{children || (
				<Typography
					sx={{
						...sizeFontCustom.cardText,
						maxWidth: mobileScreen ? '60vw' : '19vw',
						fontFamily: customDetails?.fontFamily,
						textAlign: 'left',
						...valueStyles,
					}}
				>
					{value}
				</Typography>
			)}
		</div>
	);
};

export default Field;
