import { Button, Link, List, ListItem, ListItemText, TextField, Typography, useTheme } from '@mui/material'
import sizeFontCustom from "./fontSize.styles"

function VerifyOtp({phoneNumber, setVerifyState, verifyState, handleVerify, accountConfirmLinking}) {
  const theme = useTheme();
  
  return (
    <List disablePadding sx={{ width: "100%", px: "1rem",pt:{xs:"0.75rem", md:0}  }}>
      <ListItem disablePadding>
        <ListItemText disablePadding
          sx={{
            "& .MuiListItemText-primary": {
              color: theme.palette.info.main,
              fontSize: { xs: "1rem", md: "0.85rem" },
              mt: { xs: "0.5rem", md: "0" },
              fontWeight: 600,
              mb: "0.5rem"
            },
            "& .MuiListItemText-secondary": {
              color: theme.palette.black60,
              ...sizeFontCustom.subHeading
            },
            marginBottom: "0.75rem"
          }}
          primary={"Enter OTP"}
          secondary={
            <>
              Enter OTP received on the mobile number<strong style={{ color: "black" }}> {phoneNumber}</strong>
            </>
          }
        />
      </ListItem>
      <ListItem component={'div'} disablePadding sx={{ width: "100%" }}>
        <TextField
          name="otp"
          label="OTP"
          size="small"
          fullWidth
          value={verifyState.OTP}
          placeholder="Enter OTP"
          inputProps={{ style: { fontSize: "0.875rem" } }}
          variant="outlined"
          onChange={(e) => { setVerifyState({ ...verifyState, OTP: e.target.value }) }}
          sx={{ my: { xs: "0.75rem", md: "0.725rem" } }}
        />

      </ListItem>
      <ListItem disablePadding>
        {!verifyState.isResend ?
          <Typography textAlign={"left"} sx={{ mb: "1rem", ...sizeFontCustom.subHeading }}>
            Resend OTP in
            <strong
              style={{
                color: theme.palette.error.main,
              }}>
              {" " + verifyState.time} sec
            </strong>
          </Typography>
          :
          <Link
            underline="none"
            variant="text"
            onClick={handleVerify}
            disabled={!verifyState.isResend}
            sx={{ mb: "1.25rem", cursor: "pointer", ...sizeFontCustom.subHeading }}
          >
            Resend
          </Link>
        }
      </ListItem>
      <ListItem disablePadding>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={accountConfirmLinking}
          disabled={(!verifyState.OTP) || !(verifyState.OTP.length === 6 || verifyState.OTP.length === 8)}
          sx={{
            textTransform: "capitalize",
            ...sizeFontCustom.subHeading
          }}
        >
          Submit
        </Button>
      </ListItem>
    </List>
  )
}

export default VerifyOtp