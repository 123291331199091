import React from 'react'
import Field from './Field'
import { dateFormat } from '../../../hepler/functions'

function ConsentRequestedExpires(props) {
  return (
    <>
      <Field
          property="Consent requested on"
          value={dateFormat(props?.consentDetails?.startTime, true)}
          customDetails={props?.customDetails}
      />
      <Field
        property="Consent expires on"
        value={dateFormat(props?.consentDetails?.expireTime, true)}
        customDetails={props?.customDetails}
        valueStyles={{ color: 'red' }} />
    </>
  )
}

export default ConsentRequestedExpires