import { Box, Button, useMediaQuery, useTheme } from '@mui/material';

function AddNewButton(props) {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box display={'flex'} alignItems={'center'}>
			{/* <Fab
        onClick= {props.handleAddAccount}
        color="primary"
        aria-label="add"
        sx={{
          ml: { xs: "0.5rem", md: "1.83rem" },
          width: "28px",
          height: "28px",
          minHeight: "unset",
        }}
      >
        +
      </Fab> */}
			<Button
        disabled={!props?.isActiveFlag}
				variant="text"
				sx={{
					fontSize: mobileScreen ? '0.6rem' : '0.75rem',
					border: mobileScreen ? `1px solid ${!props?.isActiveFlag?"lightgray":theme.palette.primary.main}` : '',
					mb: mobileScreen ? 0.5 : 0,
					ml: mobileScreen ? 0 : 0.5,
				}}
				onClick={props.handleAddAccount}
			>
				Add New
			</Button>
		</Box>
	);
}

export default AddNewButton;
