import { Avatar, ListItem, ListItemAvatar, ListItemText, useMediaQuery, useTheme } from "@mui/material"

function LoginRightListItem(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { number, text, fontFamily } = props
  return (
    <ListItem disablePadding={mobileScreen} sx={{ paddingLeft: "0" }}>
      <ListItemAvatar>
        <Avatar sx={{
          width: mobileScreen ? "20px" : "27px",
          height: mobileScreen ? "20px" : "27px",
          fontSize: mobileScreen ? "0.825rem" : "1rem",
          fontWeight: 700,
          marginRight: "1rem",
          backgroundColor: props.customDetails.primaryColor,
        }}>
          {number}
        </Avatar>
      </ListItemAvatar>
      <ListItemText disableTypography sx={{ fontSize: mobileScreen ? "0.825rem" : "1.125rem", fontFamily: fontFamily }} primary={text} />
    </ListItem>
  )
}

export default LoginRightListItem