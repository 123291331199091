const toastStyles = {
  toast: {
    borderRadius: "14px",
    width: "359px",
    height: "70px",
    fontSize: "0.875rem",
    bottom: "19rem !important",
    marginBottom: "3rem"
  },
};

export default toastStyles;
