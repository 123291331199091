import sizeFontCustom from "./fontSize.styles"

const styles = {
  list: {
    "listStyleType": "disc",
    "paddingTop": "0.75rem",
    ...sizeFontCustom.subHeading
  },
  discoveredAccounts: {
    maxWidth: "80vw",
    overflow: "scroll",
    pb: 0,
    '&::-webkit-scrollbar': {
      width: '0.3em',
    },
  },
  fipNotFoundContainer: {
    maxWidth: "80vw",
  }
}

export default styles