import { useContext,} from "react";
import ConsentListContext from "../Context/ConsentContext";
import ConsentCardLSPHeading from "./ConsentCardLSPHeading";
import { Box, Button,  Typography, useMediaQuery, useTheme } from "@mui/material";
import linkedAccountsSectionStyles from '../Accounts/LinkedAccountsSection.styles';
import sizeFontCustom from '../Discovery/fontSize.styles'

function FiuList(props) {
	const consentsContext = useContext(ConsentListContext);
	const consentsAll = consentsContext.consentList;
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography 
        sx={{
              m:"1.5rem 1rem 0.5rem 1rem", 
              fontWeight:700,
              ...sizeFontCustom.field,

        }}>
        No Institutions Selected!
      </Typography>
      <Box
        sx={{m:"0 1rem 3rem 1rem"}}>
        {consentsAll.map((consent,idx) => 
            <div
              onClick={() =>
                props.selectUnselectConsents(consent?.ConsentHandle)
              } 
              key={idx} 
            >
              <ConsentCardLSPHeading 
                consentDetails ={consent} 
                isActiveFlag={props.selectedConsentsState?.includes(consent?.ConsentHandle)}
                fontWeight={400}
                linkedAccountSelected={props.linkedAccountsSelected[consent?.ConsentHandle]}/>
            </div>
        )}
      </Box>
      <Box
        sx={{
          bottom:  '0rem' ,
          right:  '0rem' ,
          gap:  '1rem' ,
          width:  '100vw',
          flexWrap: 'wrap',
          ...linkedAccountsSectionStyles.buttonGroup,

        }}
      >
        <Button
          variant="contained"
          fullWidth = {mobileScreen}
          color="primary"
          onClick={() => props.handleAccept()}
          sx={{ background: theme.palette.primary.main }}
        >
          Accept
        </Button>
        </Box>
    </>
  )
}

export default FiuList