import { Avatar, Box, Checkbox, Chip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import sizeFontCustom from '../Discovery/fontSize.styles'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

function ConsentCardLSPHeading({
  consentDetails, 
  isActiveFlag ,
  fontWeight=700,
  linkedAccountSelected=null
}) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const label = linkedAccountSelected? linkedAccountSelected?.length + " accounts selected": null
  const fontSize = linkedAccountSelected? sizeFontCustom.drawerFIU: sizeFontCustom.field;
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} mb={{md:"0.5rem"}}>
      <Box display={"flex"} flexDirection={"row"} height={mobileScreen ? "64px" : "auto"} alignItems={"center"}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Avatar
          src={consentDetails?.entityInfoDetails?.entityLogoUri ? consentDetails?.entityInfoDetails?.entityLogoUri: <AccountBalanceIcon/>}
          sx={{
            "& .MuiAvatar-img": {
            objectFit: "contain",}, 
            mr: "0.678rem",
            height:40, 
            width:40
          }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: mobileScreen ? "0.25rem" : "0.5rem",
          }}  
        >
          <Typography sx={{
            ...fontSize,
            maxWidth: linkedAccountSelected? "unset":mobileScreen ? "60vw" : "19vw",
            fontWeight: fontWeight,
            textAlign: "left",
          }}>
            {consentDetails.entityInfoDetails?.entityName}
          {linkedAccountSelected && <Chip
            variant="outlined"
            label={label}
            size="small"
            sx={{
              mr: 0.5,
              ml: 0.5,
              fontSize: '0.675rem',
              width: 'fit-content',
              color: theme.palette.black60,
            }}
          />}
          </Typography>
        </Box>
      </Box>
      <Checkbox
            color={"primary"}
            checked={isActiveFlag}        
            key={Math.random()}
            name={consentDetails?.ConsentHandle}
        />
    </Box>
  )
}

export default ConsentCardLSPHeading