import { Avatar, ButtonBase, ClickAwayListener, Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, useTheme } from '@mui/material';
import stylesLogo from "../Accounts/Account.styles";
import { useState } from 'react';
import sizeFontCustom from "./fontSize.styles"

function FipdNotDiscovered(props) {
  const theme = useTheme()
  const { fipId } = props
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <Grid
      item
      xs={6}
      md={6}
    >
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            onClose={handleTooltipClose}
            open={open}
            title={global.objectFormatFip[fipId]?.productName}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <ButtonBase sx={{ width: "100%" }} onClick={handleTooltipOpen}>
              <ListItem key={fipId} sx={{ maxWidth: "95%" }} disablePadding>
                <ListItemText primary={
                  <List disablePadding >
                    <ListItem sx={{ m: "0.5rem 1.25rem 0 0", background: theme.palette.primary.light, p: "0.5rem", borderRadius: "14px" }} >
                      <ListItemIcon >
                        <Avatar
                          alt={""}
                          src={global.objectFormatFip[fipId]?.productIconUri}
                          sx={{ ...stylesLogo.avatarStyle, background: theme.palette.primary.light }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography sx={{
                            ...sizeFontCustom.subHeading,
                            color: "black",
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}>
                            {global.objectFormatFip[fipId]?.productName}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                } />
              </ListItem>
            </ButtonBase>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Grid>)
}

export default FipdNotDiscovered