import { DialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import sizeFontCustom from "../Discovery/fontSize.styles"

function CustomDialogTitle(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { children, onClose, centeredText, ...other } = props;
  return (
    <DialogTitle
      sx={{
        ...sizeFontCustom.dialogTitle,
        fontWeight: 900,
        p: "0",
        textAlign: centeredText ? "center" : "",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          size={mobileScreen ? "small" : "medium"}
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
            p: { xs: "0.5rem", md: "0.75rem" },
            color: theme.palette.primary.main,
            background: theme.palette.primary.light,
          }}
        >
          <CloseIcon sx={{ fontSize: "0.75rem" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default CustomDialogTitle;
