import { Box, Link, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'

function RedirectionCard(props) {
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMultipleConsent = global?.consentListLength > 1 ? true : false

  const handleRedirect = () => {
    window.open("https://aaweb.finvu.in/", "_blank"); // Web App Prod link
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: '100vh',
        alignItems: "center",
        padding: mobileScreen ? "2.25rem 1.5rem 5.5rem 1.5rem" : "6rem 1.875rem",
        minWidth: mobileScreen ? "100vw" : "auto",
        backgroundColor: theme.palette.primary.light,
        gap: mobileScreen ? "1.25rem" : "1.5rem",
      }}
    >
      <Paper 
        sx={{ 
            padding: `${mobileScreen ? "2rem 3rem" : "2.5rem 4rem"}`,
            gap: mobileScreen ? "1.25rem" : "1.5rem",
             }}>
        <Typography sx={{
          fontSize: mobileScreen ? "1.25rem" : "1.5rem",
          fontWeight: 700,
          color: theme.palette.primary.main,
          textAlign: "start",
          mb: "0.75rem"
        }}>
          {props.statusCode === 403
            ? "Something Went Wrong"
            : !isMultipleConsent
              ? (props.statusCode === 200
                ? "Data Sharing Approved"
                : "Data Sharing Denied")
              : "Consents Actioned"}
        </Typography>
        <Typography sx={{ textAlign: "start", mb: "0.75rem" }} >
          Download FINVU App or visit &nbsp;
          <Link underline={"none"} onClick={handleRedirect} sx={{ cursor: "pointer" }}>
            Finvu Web App &nbsp;
          </Link>
          to manage consents and more
        </Typography>
        {props.statusCode !== 403 && global.isRedirect === 'true' && props.isCloseWindow === false ?
          <>
            <Typography fontWeight={700} textAlign={"start"} mb={"1rem"}>
              Redirecting to {global.entityName} in
              <Typography
                component={"span"}
                fontSize={mobileScreen ? "1.5rem" : "1.875rem"}
                color={theme.palette.black60}>
                {" " + props.time}
              </Typography>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.error.main
              }}
            >
            Please DO NOT hit REFRESH or BACK
          </Typography>
        </> :
          <Typography fontWeight={700} textAlign={"start"}>
            You may close the window now.
          </Typography>
        }
        
      </Paper>
    </Box>
  )
}

export default RedirectionCard