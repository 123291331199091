export const accountInfoPopoverContent = [
	{
		title: 'Profile',
		content:
			'Information on your account such as account holder name, address, etc.',
	},
	{
		title: 'Summary',
		content: 'Summary view of your account such as account balance etc.',
	},
	{
		title: 'Transactions',
		content:
			'List of transactions done in the account for the fetch period similarly to monthly statements',
	},
];
export const dynamicPurposePopoverContentGenerator = (code, category) => {
	return [
		{
			title: 'Code',
			content: code,
		},
		{
			title: 'Category',
			content: category,
		},
		{
			title: 'Help',
			content: 'support@cookiejar.co.in',
		},
	];
};
