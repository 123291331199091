import { AppBar, Box, Toolbar } from '@mui/material';
import headerStyles from './Header.styles';
import finvu from '../../assets/finvu.png';
function Header(props) {
	return (
		<Box sx={headerStyles.container}>
			<AppBar sx={headerStyles.appBar}>
				<Toolbar sx={headerStyles.p0}>
					<Box sx={headerStyles.imageBox}>
						{props.customDetails?.logo ? (
							<Box sx={headerStyles.logoContainer}>
								<img
									src={props.customDetails?.logo}
									alt="logo"
									style={headerStyles.logo}
								/>
							</Box>
						) : null}
						{/* <Box sx={{width: {xs: "4rem", md:"6.25rem"}, height: {xs: "2rem", md:"2.125rem"}}}>
                <img src={finvu} alt="finvu"  style={headerStyles.logo}/>
            </Box> */}
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

export default Header;
