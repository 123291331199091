import React, { useState, useContext } from 'react';
import { Button, useMediaQuery,
	useTheme, } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';

const FloatingButton = ({ numberOfUnviewedConsents, handleClick }) => {
  const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (numberOfUnviewedConsents === 0) {
		return null;
	}
  
	let buttonText = `${numberOfUnviewedConsents} more consent${
		numberOfUnviewedConsents > 1 ? 's' : ''
	}`;
  const otherText = ". Scroll to bottom to accept";
  buttonText = !mobileScreen? buttonText + otherText: buttonText;
	return (
		<Button
			sx={{
				position: 'fixed',
				bottom: '8rem',
				transform: 'translateX(-50%)',
				left: '50%',
				fontSize: '0.675rem',
				zIndex: 1502,
				width: 'fit-content',
        textTransform: "none"
			}}
			variant="contained"
			size="small"
			color="primary"
			startIcon={<SouthIcon />}
			onClick={handleClick}
		>
			{buttonText}
		</Button>
	);
};

export default FloatingButton;
