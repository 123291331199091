import { ListItemIcon, Popover, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useState } from 'react';

const CustomPopover = ({ content, isActiveFlag, fieldName }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open
		? fieldName === 'purpose'
			? 'purpose-popover'
			: 'accountInfo-popover'
		: undefined;

	return (
		<div>
			<Tooltip aria-describedby={id} onClick={handleClick}>
				<ListItemIcon>
					<InfoOutlinedIcon
						color={isActiveFlag ? 'primary' : 'disabled'}
						sx={{ fontSize: 16 }}
					/>
				</ListItemIcon>
			</Tooltip>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<div>{content}</div>
			</Popover>
		</div>
	);
};

export default CustomPopover;
