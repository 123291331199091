import { Box, Button, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import sizeFontCustom from "../Discovery/fontSize.styles"


function VerifyDetailsDOB(props) {
  const { handleClick,
    date,
    setDate,
    pan,
    setPan,
    isDobRequired,
    isPanRequired, } = props;

  const handleChange = (event) => {
    setPan(event.target.value);
  };

  const isPANValid = (pan) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/i; // case-insensitive
    return panRegex.test(pan);
  };

  const onChange = (selectedDate) => {
    if (selectedDate && selectedDate !== undefined) {
      setDate(selectedDate.format("YYYY-MM-DD"));
    }
  };
  return (
    <Box
      sx={{
        p: "0.63rem 0",
        display: "flex",
        flexDirection: "column",
        gap:"1rem",
        alignItems: "flex-start",
      }}
    >
      {isDobRequired && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer sx={{ width: "100%" }} components={['DatePicker']}>
            <DatePicker
              slotProps={{ textField: { size: 'small', ...sizeFontCustom.subHeading } }}
              format="DD-MM-YYYY"
              sx={{ width: "100%", "& .MuiFormLabel-root-MuiInputLabel-root": { fontSize: "0.75rem" } }}
              label="Date of Birth"
              value={date}
              onChange={onChange}
            />
          </DemoContainer>
        </LocalizationProvider>
      )}
      {isPanRequired && (
        <TextField
          label="PAN"
          placeholder="Enter PAN"
          focused={true}
          size="small"
          variant="outlined"
          onChange={handleChange}
          fullWidth
          inputProps={{ style: { fontSize: "0.875rem" } }}
          sx={{  ...sizeFontCustom.subHeading }}
        />
      )}
      <Button
        variant="contained"
        disabled={
          (isDobRequired && (date === "Invalid Date" || date === "" || !date)) ||
          (isPanRequired && !isPANValid(pan))
        } onClick={handleClick}
        sx={{
          textTransform: "capitalize",
          // marginTop: "0.62rem",
          width: "100%",
        }}
      >
        Submit
      </Button>
    </Box>
  );
}

export default VerifyDetailsDOB;
