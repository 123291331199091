import { Box, Dialog, DialogContentText, Typography } from "@mui/material";
import CustomDialogTitle from "./CustomDialogBox";
import sizeFontCustom from "../Discovery/fontSize.styles"

DialogBox.defaultProps = {
  mobileNumber: "",
  boxPadding: "1.25rem 1.25rem 1.875rem 1.25rem",
  isTitleCentered: false,
  centeredSubTitle: false,
  subtitleXSFontSize: "0.75rem",
  subTitleMaxWidth: "80%",
  centeredChildren: false,
  titleMaxWidth: "auto",
};

function DialogBox({
  open,
  handleClose,
  title,
  subTitle,
  mobileNumber,
  children,
  boxPadding,
  centeredText,
  subTitleMaxWidth,
  centeredChildren,
  marginTopChildren,
}) {
  const showTitle = title ? true : false;
  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: "14px" } }}>
      <Box
        sx={{
          padding: boxPadding,
          display: "flex",
          flexDirection: "column",
          alignItems: centeredChildren ? "center" : "",
          borderRadius: "14px",
          maxWidth: "48rem"
        }}
      >
        {showTitle ?
          <CustomDialogTitle onClose={handleClose} centeredText={centeredText}>
            {title}
            <DialogContentText
              sx={{
                mt: { xs: "0.5rem", md: "0.35rem" },
                ...sizeFontCustom.heading,
                maxWidth: subTitleMaxWidth,
              }}
            >
              {subTitle}
              {mobileNumber && (
                <Typography
                  sx={{
                    display: "inline",
                    fontWeight: 700,
                    color: "#111",
                    ...sizeFontCustom.subHeading,
                  }}
                >
                  {mobileNumber}
                </Typography>
              )}
            </DialogContentText>
          </CustomDialogTitle> : null}
        <Box sx={{ marginTop: { xs: "0.25rem", md: `${marginTopChildren}` } }}>{children}</Box>
      </Box>
    </Dialog>
  );
}

export default DialogBox;
