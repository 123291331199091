import { Box, Button, FormControl, FormControlLabel, IconButton, Link, List, ListItem, ListItemText, Paper, Radio, RadioGroup, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WhyShare from '../Sections/WhyShare';
import styles from './NoAccountContainer.styles';
import sizeFontCustom from "../Discovery/fontSize.styles"

function NoAccountContainer(props) {
  const { setSuperState, superState, handleBack, handleOTPChange, sendOtp, verifyOtp, handleToggleNumber } = props
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [phoneNumberAlternate, setPhoneNumberAlternate] = useState('');
  const [changeNumber, setChangeNumber] = useState("other")
  const handleChange = (event) => {
    setChangeNumber(event.target.value);
  };
  const handleSubmit = async () => {
    if (superState.noAccount === 101) {
      if (changeNumber === "other") {
        await setSuperState(phoneNumberAlternate, null)
        sendOtp()
      } else {
        handleToggleNumber(changeNumber)
      }

    } else if (superState.noAccount === 102) {
      verifyOtp()
    } else {
      await setSuperState(null, 101)
    }
  }
  return (
    <Box
      sx={{
        flex: "2",
        display: "flex",
        justifyContent: { xs: "center", md: "center" },
        flexDirection: "column",
        minHeight: !mobileScreen ? '100vh' : "none",
        alignItems: "center",
        padding: mobileScreen ? "2.25rem 0 0 0" : "6rem 1.875rem",
        minWidth: mobileScreen ? "100vw" : "auto",
        backgroundColor: theme.palette.primary.light,
        gap: mobileScreen ? "1.25rem" : "1.5rem",

      }}>
      <Paper
        sx={{
          ...styles.container,
          margin: mobileScreen ? "0 1.5rem 0 1.5rem" : null,
        }}>
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemText
              sx={{
                "& .MuiListItemText-primary": { color: theme.palette.info.main,  fontWeight: 900, mb: "0.75rem" },
                "& .MuiListItemText-secondary": { color: theme.palette.black60, ...sizeFontCustom.subHeading },
                marginBottom: "0.5rem"
              }}
              primary={
                <Box
                  sx={styles.heading}>
                  {global.isAddAccount &&
                    <IconButton onClick={handleBack} sx={{ p: 0 }}>
                      <ArrowBackIcon sx={{ color: props.customDetails.primaryColor, fontSize: "1.25rem" }} />
                    </IconButton>
                  }
                  <Typography
                    sx={styles.changeNumberText}
                  >
                    Change Mobile Number
                  </Typography>
                </Box>
              }
              secondary={
                <>
                  {
                    superState.noAccount === 102 ?
                      `OTP sent to ${phoneNumberAlternate}`
                      : (superState.noAccount === 101 ?
                        <>
                          Select an already verified number for finding your
                          accounts or use another number
                          <FormControl sx={{ display: "block" }}>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={changeNumber}
                              onChange={handleChange}
                            >
                              {global.mobileNumberArray.map((el, i) =>  
                                <FormControlLabel 
                                  key={el} 
                                  value={el} 
                                  control={<Radio size='small' />} 
                                  label={<Typography sx={sizeFontCustom.subHeading}>{el}</Typography>}
                                  />
                              )}
                              <FormControlLabel 
                                value="other" 
                                control={<Radio size='small' />} 
                                label={<Typography sx={sizeFontCustom.subHeading}>{"Add New Number"}</Typography>} 
                            />
                            </RadioGroup>
                          </FormControl>
                        </>
                        : <>
                          We have discovered no bank accounts associated with mobile number {" "}
                          <strong>{props.match.params?.number}</strong></>
                      )
                  }
                </>
              }
            />
          </ListItem>
          <>
            {
              superState.noAccount === 101 && changeNumber === "other" ?
                <ListItem disablePadding>
                  <TextField
                    name='Phone Number'
                    variant='outlined'
                    type='number'
                    size='small'
                    label={<Typography sx={sizeFontCustom.heading}>Enter Phone Number</Typography>}
                    inputProps={{ style: { fontSize: "0.875rem" } }}
                    value={phoneNumberAlternate}
                    onChange={(e) => setPhoneNumberAlternate(e.target.value)}
                    fullWidth
                    sx={{ mb: "1rem" }}
                    />
                </ListItem>
                :
                (superState.noAccount === 102 ?
                  <>
                    <TextField
                      name='OTP'
                      fullWidth
                      label="Enter OTP"
                      value={superState.OTP}
                      onChange={handleOTPChange}
                      sx={{ mb: "1.25rem" }}

                    />
                    {!superState.isResend
                      ?
                      <Typography
                        sx={styles.resendText}>
                        Resent OTP in
                        <strong
                          style={{
                            color: theme.palette.error.main,
                          }}>
                          {" " + superState.time} sec
                        </strong>
                      </Typography>
                      :
                      <Link
                        display={"block"}
                        underline='none'
                        disabled={!superState.isResend}
                        onClick={sendOtp}
                        sx={{
                          cursor: "pointer",
                          m: "0 0 0.875rem 0",
                          color: `${(superState.isResend) ? theme.palette.primary.main : 'grey'}`,
                        }}
                      >
                        Resend OTP
                      </Link>
                    }
                  </>
                  : null
                )
            }
          </>
          <ListItem disablePadding>
            <Button
              variant='contained'
              fullWidth
              disabled={superState.noAccount === 101 ? changeNumber === "other" ? phoneNumberAlternate.length !== 10 : false : superState.noAccount === 102 ? superState.OTP.length < 4 : false}
              onClick={handleSubmit}
              sx={{
                textTransform: "capitalize"
              }}
            >
              Continue
            </Button>
          </ListItem>
        </List>
      </Paper>
    </Box>
  )
}

export default NoAccountContainer