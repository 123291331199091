import { createContext, useState } from 'react';

const ConsentListContext = createContext();

export  function ConsentContext({children}) {
  const updateConsentList = (consents) => {
    setState((prevState) => ({...prevState, consentList: consents, selectedConsents: consents}))
  };
  const updateActiveSlide = (slideIdx) => {
    setState( (prevState) => ({...prevState, activeSlide: slideIdx}))
  };
  const updateSelectedConsentList = (consents) => {
    setState((prevState) => ({...prevState, selectedConsents: consents}))
  };
  const updateDeniedConsentsMultiConsent = (consents) => {
    setState((prevState) => ({...prevState, deniedConsentsMultiConsent: consents}))
  };
  const updateAcceptedConsentsMultiConsent = (consents) => {
    setState((prevState) => ({...prevState,acceptedConsentsMultiConsent: consents}))
  };
  const updateCommonConsentFiTypesGroup = (consentGroup) => {
    setState((prevState) => ({...prevState,commonConsentFiTypesGroup: consentGroup}))
  };
  const updateActiveConsent = (activeConsent) => {
    setState( (prevState) => ({...prevState, activeConsent: activeConsent}))
  };
  const [state, setState] = useState({
    consentList: [],
    activeSlide:0,
    updateConsentList: updateConsentList,
    updateActiveSlide: updateActiveSlide,
    selectedConsents: [],
    acceptedConsentsMultiConsent: [],
    deniedConsentsMultiConsent: [],
    commonConsentFiTypesGroup: {},
    updateSelectedConsentList: updateSelectedConsentList,
    updateAcceptedConsentsMultiConsent: updateAcceptedConsentsMultiConsent,
    updateDeniedConsentsMultiConsent: updateDeniedConsentsMultiConsent,
    updateCommonConsentFiTypesGroup: updateCommonConsentFiTypesGroup,
    activeConsent:0,
    updateActiveConsent: updateActiveConsent,
  }
  )
  return (
    <ConsentListContext.Provider value={state}>
      {children}
    </ConsentListContext.Provider>
  )
}


export default ConsentListContext;

