import React from 'react';
import { dynamicPurposePopoverContentGenerator } from '../../constants/staticContent';
import { useMediaQuery, useTheme } from '@mui/material';

const PurposeContent = ({ code, category }) => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 4,
				padding: '1rem',
			}}
		>
			{dynamicPurposePopoverContentGenerator(code, category).map((text) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
						maxWidth: mobileScreen ? '15rem' : '20rem',
					}}
				>
					<div style={{ fontWeight: 700 }}>{text.title}</div>
					<div>{text.content}</div>
				</div>
			))}
		</div>
	);
};

export default PurposeContent;
