import { Component } from 'react'
import AuthApis from '../hepler/authApis.service';
import { postMessage } from '../utils/common.utils';
import RedirectionCard from './Sections/RedirectionCard';

export default class ConsentStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: 5,
            isCloseWindow: false
        };
        this.authApis = new AuthApis()
    }
    async componentDidMount() {
        if (global.isRedirect === 'true') {
            await this.redirect()
        } else {
            await this.logout()
        }
    }

    startTimer = (payload) => {
        this.id = setInterval(() => {
            this.setState((prevState, prevProps) => ({
                time: prevState.time - 1
            }));
            if (this.state.time === 0) {
                clearInterval(this.id);
                this.setState({ time: 0 });
                if (payload['redirectUrl'].includes('?')) {
                    window.location.replace(payload['redirectUrl'] + "&ecres=" + payload["encryptedResponse"] + "&resdate=" + payload['responseDate'] + "&fi=" + payload['encryptedFiId'])
                } else {
                    window.location.replace(payload['redirectUrl'] + "?ecres=" + payload["encryptedResponse"] + "&resdate=" + payload['responseDate'] + "&fi=" + payload['encryptedFiId'])
                }
                if (this.props?.location?.state?.statusCode === 200) {
                    postMessage('consent', 'ACCEPT')
                } else {
                    postMessage('consent', 'DENY')
                }
            }
        }, 1000);
    }

    delay = ms => new Promise(res => setTimeout(res, ms));

    logout = async () => {
        this.authApis.logout(global.userId).then(res => {
            console.log("res", res);
            if (res?.status === "SUCCESS") {
                if (this.props?.location?.state?.statusCode === 200) {
                    postMessage('consent', 'ACCEPT')
                } else {
                    postMessage('consent', 'DENY')
                }
                console.log(res.message)
            } else {
                console.log(res.message)
            }

        })
    }
    redirect = () => {
        let code = (this.props?.location?.state?.statusCode === 200) ? 0 : 1;
        this.authApis.encryptRequest(code).then(res => {
            console.log("res redirect request", res);
            let payload = res?.payload;
            this.authApis.logout(global.userId).then(async res => {
                console.log("res", res);
                if (res?.status === "SUCCESS") {
                    if (this.props?.location?.state?.statusCode === 200) {
                        postMessage('consent', 'ACCEPT')
                    } else {
                        postMessage('consent', 'DENY')
                    }
                    if (this.props?.location?.state?.statusCode !== 403 && payload['redirectUrl'] && (payload['redirectUrl'] !== "none")) {
                        this.startTimer(payload)
                    } else {
                        this.setState({ isCloseWindow: true })
                    }
                    console.log(res?.message)
                } else {
                    console.log(res?.message)
                    this.setState({ isCloseWindow: true })
                }
            })
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            if (this.props.history.action === 'POP') {
                window.confirm("Are you sure to go back ? Consent will not be actioned.")
            }
        }
    }
    render() {
        return (
            <RedirectionCard
                statusCode={this.props?.location?.state?.statusCode}
                isCloseWindow={this.state.isCloseWindow}
                time={this.state.time}
            />
        )
    }
}
