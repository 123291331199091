import {
  Avatar,
  Card,
  Checkbox,
  ListItem,
  ListItemText,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import bankCardStyle from "./OtherFIUCard.styles";
import sizeFontCustom from "../Discovery/fontSize.styles"
import { useState } from "react";

function OtherFIUCard({
  xs,
  md,
  lg,
  bankObject,
  handleOtherFipCheckboxChange,
  checkedBanksList,
  fontSize,
  boxShadow,
  height,
}) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipClick = () => {
    if (!isTooltipOpen && checkedBanksList?.length >= 5) setIsTooltipOpen(true);
    else setIsTooltipOpen(!isTooltipOpen);
  }

  const handleClick = (event) => { event.target.checked = true 
    return  handleOtherFipCheckboxChange(event, bankObject)}
  return (
    <Grid item xs={xs} sm={6} md={md} lg={lg} sx={{ pt: "0.94rem!important" }}>
      <Tooltip
        title={"You can only select maximum 5 institutions at a time"}
        arrow
        open={isTooltipOpen}
        sx={{ border: "sloid" }}
      >
        <Card
          onClick={checkedBanksList?.length < 5 ? handleClick : handleTooltipClick}
          sx={{
            ...bankCardStyle.card,
            cursor:"pointer",
            boxShadow: { boxShadow },
            height: { height },
          }}
        >
          <ListItem sx={bankCardStyle.listItem}>
            <Avatar
              src={bankObject?.productIconUri}
              alt={bankObject?.bankName}
              sx={bankCardStyle.avatar}
            />
            <ListItemText
              primary={
                <Typography
                  sx={{
                    ...bankCardStyle.bankName,
                    ...sizeFontCustom.subHeading,
                  }}
                >
                  {bankObject?.productName}
                </Typography>
              }
            />
            {global?.allowMultipleFipDiscovery === "true" && 
              <Checkbox
                disableTouchRipple
                disableRipple
                disabled={checkedBanksList?.length >= 5}
                sx={{ paddingRight: "0" }}
                // checked={checkedBanksList.some((bank) => bank.fipId === bankObject.fipId)}
                // onChange={(event) => handleOtherFipCheckboxChange(event, bankObject)}
              />
              }
          </ListItem>
        </Card>
      </Tooltip>
    </Grid>
  );
}

export default OtherFIUCard;
