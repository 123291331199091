import sizeFontCustom from "../Discovery/fontSize.styles"

const styles = {
  list: {
    "list-style-type": "disc"
  },
  heading: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginBottom: "0.75rem",
  },
  changeNumberText: {
    ...sizeFontCustom.heading,
    fontWeight: "400",
    pl: 1
  },
  container: {
    maxWidth: {
      xs: "20rem",
      md: "26rem"
    },
    minWidth: {
      xs: "20rem",
      md: "26rem"
    },
    padding: "1.25rem",
    textAlign: "left"
  },
  resendText: {
    mb: "0.875rem",
    ...sizeFontCustom.subHeading,
    textAlign: "left"
  },
  whyShare: {
    zIndex: 0,
    display: "flex",
    mb: "1.5em",
    width: "100vw",
  }
}

export default styles