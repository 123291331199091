import { Box, Paper, useTheme } from '@mui/material'
import LoginRightContent from './LoginRightContent';
import { useState } from 'react';
import SetTheme from '../common/SetTheme';

function LoginRight(props) {
  const theme1 = useTheme();
  // eslint-disable-next-line no-unused-vars
  const [theme, setTheme] = useState(props?.theme ? props.theme : theme1)

  return (
    <Box sx={{
      display: { xs: "none", sm: "none", md: "flex" },
      flexDirection: "column",
      justifyContent: "start",
      minHeight: '100vh',
      alignItems: "flex-start",
      flex: 2,
      backgroundColor: props.customDetails.primaryColor,
      // backgroundColor: theme.palette.primary.main,
      padding: {
        md: "9.125rem 5.5rem",
        lg: "9.125rem 7.5rem"
      }
    }}>
      <SetTheme customDetails={props.customDetails} setTheme={setTheme} />
      <Paper sx={{
        padding: "2.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto"
      }}>
        <LoginRightContent {...props} />

      </Paper>
    </Box>
  )
}

export default LoginRight