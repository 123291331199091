const bankCardStyle = {
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    borderRadius: "0.75rem",
    border: "0.0625rem solid var(--storke-stroke-light, #E3E9EE)",
  },
  avatar: {
    width: "1.875rem",
    height: "1.875rem",
    borderRadius: "0",
    "& .MuiAvatar-img": {
      objectFit: "contain",
    },
    marginRight: "0.62rem",
  },
  listItem: {
    paddingRight: {
      xs: "0.75rem!important",
      md: "0.625rem!important",
    },
    paddingLeft: {
      xs: "0.75rem!important",
      md: "1.25rem!important",
    },
  },
  bankName: {
    fontWeight: "400",
  },
};
export default bankCardStyle;
