import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#F3F5FD",
      main: "#3053D3",
    },
    secondary: {
      main: "#FFFFFF",
    },
    error: {
      main: "#E94B4B",
    },
    success: {
      main: "#20BE79",
      light: "#E9F8F2",
    },
    info: {
      main: "#000000",
    },
    black60: "#81858F",
    black40: "#A5AAB8",
    backgroundMobileSubHeader: "#2B3056",
    textBlack: "#111111",
    tagRed: {
      main: "#CA2626",
      light: "#FDEDED",
    },
  },
  typography: {
    fontFamily: "lato"
    // fontFamily: [
    //   "Roboto",
    //   "-apple-system",
    //   "BlinkMacSystemFont",
    //   '"Segoe UI"',
    //   "Arial",
    //   "sans-serif",
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(","),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: "14px",
          borderColor: "#D8E1EE",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          opacity: 1
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "124px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: "unset",
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "unset",
        }
      }
    }
  },
});

export default theme;
