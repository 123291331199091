const discoveryStyles = {
  container: {
    flex: "2",
    display: "flex",
    justifyContent: { xs: "start", md: "start" },
    flexDirection: "column",
    minHeight: '100vh',
    alignItems: "start",
    overflowY: "scroll",
    paddingTop: {xs:"5.25rem", md:"none"},
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  heading: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center"
  },
  headingText: {
    fontSize: {
      xs: "1.25rem",
      md: "1.25rem",
    },
    fontWeight: "900",
    marginBottom: {
      sm: "0.75rem",
      xs: "0.75rem",
      md: "0",
    },
  },
  nextButton: {
    textTransform: "capitalize",
    width: "15%",
  },
  subTitleText: {
    fontSize: {
      xs: "1rem",
      md: "0.875rem",
    },
    textAlign: "left"
  },
  changeNumberLink: { cursor: "pointer", textDecoration: "none", fontWeight: "700", textTransform: "capitalize" },
  discoveredAccountsContainer: {
    gap: {
      xs: "1.875rem",
      md: "0.675rem",
      lg: "0.975rem"
    }
  }
};
export default discoveryStyles;