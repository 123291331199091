import { Avatar, Checkbox, Chip, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material'
import stylesLogo from "../Accounts/Account.styles";
import { capitalize } from '../../hepler/functions';
import sizeFontCustom from "./fontSize.styles"

function FipDiscoveredAccounts(props) {
  const theme = useTheme()
  const { account, handleCheckedState, selectedAccounts, fipId, isLinked, isVerified } = props
  const handleClick = (event) => {
    if(!selectedAccounts.includes(account)){
      event.target.checked = true
    }else{
      event.target.checked = false
    }
    handleCheckedState(event, account)  
  }
  return (
    <ListItem onClick={handleClick} key={account.accRefNumber} sx={{ ml: "0.75rem", maxWidth: "95%" }} disablePadding>
      <ListItemIcon sx={{ minWidth: 0, mr: "0.25rem" }}>
        {!(isLinked || isVerified?.includes(account.accRefNumber) || isVerified?.length>0) &&
          <Checkbox
            sx={{ padding: 0 }}
            size="small"
            key={Math.random()}
            // onChange={(event) => handleCheckedState(event, account)}
            name={account.accRefNumber}
            checked={selectedAccounts.includes(account) || false}
          />
        }
      </ListItemIcon>
      <ListItemText primary={
        <List disablePadding >
          <ListItem 
            sx={{
                 m: "0.5rem 1.25rem 0 0", 
                 background: theme.palette.primary.light, 
                 p: "0.5rem", 
                 borderRadius: "14px" 
                }} >
            <ListItemIcon >
              <Avatar
                alt={""}
                src={global.objectFormatFip[fipId]?.productIconUri}
                sx={{ ...stylesLogo.avatarStyle, background: theme.palette.primary.light }}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                "& .MuiListItemText-primary": {
                  color: theme.palette.info.main,
                  ...sizeFontCustom.subHeading
                },
                "& .MuiListItemText-secondary": { color: theme.palette.black60, fontSize: "0.75rem" },
                borderRadius: "14px", m: 0
              }}
              primary={capitalize(account.accType)}
              secondary={account.maskedAccNumber.replaceAll("X", "\u2022")} />
            {(isLinked || isVerified?.includes(account.accRefNumber)) && 
              <Chip
              variant="outlined"
              label={<Typography sx={sizeFontCustom.subHeading}>Linked</Typography>}
              sx={{backgroundColor: theme.palette.primary.white}}
              size='small'
              color={"success"}
            />
                }
          </ListItem>
        </List>
      } />
    </ListItem>)
}

export default FipDiscoveredAccounts