import { useMediaQuery, useTheme } from '@mui/material';
import toastStyles from './Toast.styles';
import { ToastContainer } from 'react-toastify';

const Toast = () => {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<ToastContainer
			position={mobileScreen ? 'bottom-center' : 'bottom-left'}
			autoClose={2000}
			hideProgressBar
			newestOnTop
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable={false}
			pauseOnHover
			closeButton={false}
			style={toastStyles.toast}
		/>
	);
};

export default Toast;
